<template>
    <div style="margin-top: 120px">
        <b-form @submit.stop.prevent="onSubmit" id="test">
            <b-form-group label="Name" invalid-feedback="This field is required">
                <b-form-input
                    v-model="form.name"
                    :state="validateState('name')"
                />
            </b-form-group>
        </b-form>

            <b-button type="submit" form="test" variant="primary">Submit</b-button>
            <b-button class="ml-2" @click="resetForm()">Reset</b-button>
    </div>
</template>

<style>
body {
    padding: 1rem;
}
</style>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

export default {
    mixins: [validationMixin],
    data() {
        return {
            foods: [
                { value: null, text: "Choose..." },
                { value: "apple", text: "Apple" },
                { value: "orange", text: "Orange" },
            ],
            form: {
                name: null,
                food: null,
            },
        };
    },
    validations: {
        form: {
            food: {
                required,
            },
            name: {
                required,
                minLength: minLength(3),
            },
        },
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        resetForm() {
            this.form = {
                name: null,
                food: null,
            };

            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        onSubmit() {
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }

            alert("Form submitted!");
        },
    },
};
</script>